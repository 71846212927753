import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Leadership = () => {
    return (
        <Layout>
            <Seo title="Leadership Team" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Leadership Team</h1>
                    </div>
                </div>
                <div className="row mb-2 ">
                    <div className="col">
                        <h2>Board of Directors</h2>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxl-5 g-3 mb-3">
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/petershaerf.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Peter Shaerf</h5>
                                <p className="card-text">Non-Exec Chairman</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/muditpaliwal.png"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Mudit Paliwal</h5>
                                <p className="card-text">Founder & CEO</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/elisabethturnbull.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Elizabeth Turnbull</h5>
                                <p className="card-text">Independent Non-Exec Director</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/leliakonyn.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Lelia Konyn</h5>
                                <p className="card-text">Independent Non-Exec Director</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/michellebockman.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Michelle Bockmann</h5>
                                <p className="card-text">Independent Non-Exec Director</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-2 ">
                    <div className="col">
                        <h2>Management</h2>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxl-5 g-3 mb-3">
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/muditpaliwal.png"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Mudit Paliwal</h5>
                                <p className="card-text">Founder & CEO</p>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/andrewbenjamin.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Andrew Benjamin</h5>
                                <p className="card-text">EVP Logistics & Transportation</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/christodd.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Chris Todd</h5>
                                <p className="card-text">EVP Energy Supply & Transition</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/carolinehuot.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Caroline Huot</h5>
                                <p className="card-text">SVP Asset Management</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage
                                aspectRatio={1}
                                className="card-img-top "
                                src="../images/management/joenelson.jpg"
                                alt="paliwal"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Joseph Nelson</h5>
                                <p className="card-text">CFO</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col">
                        <div className="card-body h-100 bg-dark " style={{ padding: '16px' }}>
                            <blockquote className="blockquote text-light mb-0">
                                <p>"Leading through action, experience & dedication."</p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Leadership
